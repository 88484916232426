import React from 'react'

const otherPage = () => (
  <div className="container-fluid">
    <div className="row">
      <div className="col-md-12">
        This is another page!
      </div>
    </div>
  </div>
);

export default otherPage;
