/** @jsx jsx */
// eslint-disable-next-line
import { useState } from 'react';
import get from 'lodash.get';
import { Modal, Button, TextField, colors } from '@cimpress/react-components';
import { SkuCard } from '@cimpress-technology/ff-react-components/lib/SkuSelector';
import { IntlProvider } from 'react-intl';
import { css, jsx } from "@emotion/core";
import { Spinner } from '@cimpress/react-components/lib/shapes';
import IconCheckCircleAlt from '@cimpress-technology/react-streamline-icons/lib/IconCheckCircleAlt';
import { createConversation, postMessage } from '../../services/Inquiry';

const messageStyle = css({
  'textarea.form-control': {
    height: '120px'
  }
});

const closeButtonStyle = css`
  float: left
`;

const sendButtonStyle = css`
  float: right
`;

const successMessageStyle = css`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 24px;
`;

const headerStyle = css`
  font-weight: normal;
`;

const spinnerStyle = css`
  text-align: center;
`;

const FFS_EMAIL_ADDRESS = 'FinancialFlowsSupport@cimpress.com';

export function InquiryModal({ account, onCloseModal, show, skuInfo }) {
  const { name } = skuInfo;
  const { accountName, accountId } = account;

  const [text, setText] = useState("");
  const [messageSending, setIsMessageSending] = useState(false);
  const [messageSent, setIsMessageSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const sendMessage = (async () => {
    let errorMessage = null;
    let errorCode = null;

    setIsMessageSending(true);

    try {
      const subject = `Interest in purchasing SKU: ${name}`;
      await createConversation(subject, accountId).then(response => postMessage(response.id, text));
    } catch (error) {
      errorCode = get(error, `response.status`);
      errorMessage = errorCode
        ? `An error occured sending the message with status code ${errorCode}`
        : 'An unknown error occured sending the message';
    }

    setErrorMessage(errorMessage);
    setIsMessageSending(false);
    setIsMessageSent(true)
  })

  const skuCard =
    <span>
      <b>Product Attached</b>
      <IntlProvider locale="en">
        <SkuCard className="selected-sku-bin__content-sku-card" skuInfo={skuInfo} />
      </IntlProvider>
    </span>

  const textField =
    <TextField
      css={messageStyle}
      name="textarea"
      type="textarea"
      value={text}
      onChange={e => setText(e.target.value)}
    />

  let body;
  if (errorMessage) {
    body =
      <div className="alert alert-dismissible alert-danger">
        <p>
          <strong>
            <i>{errorMessage}</i>
          </strong>
        </p>
        <p>
          {'Please contact '}
          <a href={`mailto:${FFS_EMAIL_ADDRESS}`}>
            {'Financial Flows Support'}
          </a>{' '}
          {'for assistance'}
        </p>
      </div>
  } else if (messageSending) {
    body = <Spinner css={spinnerStyle}></Spinner>
  } else if (!messageSent) {
    body = <span>{textField}{skuCard}</span>
  } else if (messageSent) {
    body =
      <div css={successMessageStyle}>
        <IconCheckCircleAlt color={colors.success.base} size="5x" />
        <h4 css={headerStyle}>{"Success!"}</h4>
        <p>{"Your message has been sent"}</p>
      </div>
  }

  const disableSend = messageSent || !text || messageSending;

  return (
    <Modal
      show={show}
      title={`Send message to ${accountName}`}
      closeButton
      closeOnOutsideClick
      onRequestHide={onCloseModal}
      footer={
        <div>
          <Button onClick={onCloseModal} css={closeButtonStyle} className={`btn btn-default`}>{"Close"}</Button>
          <Button onClick={sendMessage} disabled={disableSend} css={sendButtonStyle} className={`btn btn-primary`}>{"Send"}</Button>
        </div>
      }>
      {body}
    </Modal>
  );
}

export default InquiryModal;
