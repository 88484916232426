import React, { useState } from "react";
import {
  Button,
  ButtonGroup,
  Tooltip
} from "@cimpress/react-components";

const AddAttachmentButton = () => {
  const [opened, setOpened] = useState(false);

  return (
    <Tooltip
      direction="top"
      variety="popover"
      contents={
        <ButtonGroup type="vertical">
          <Button type="link">
            <i className="fa fa-tag" />
            Attach Products
          </Button>
        </ButtonGroup>
      }
      onClickOutside={() => opened && setOpened(false)}
      show={opened}
    >
      <Button
        onClick={() => setOpened(true)}
        type="link"
        style={{ padding: "8px 0 8px 8px", margin: "0 10px" }}
      >
        <i className="fa fa-plus" />
      </Button>
    </Tooltip>
  );
};

export default AddAttachmentButton;
