import PropTypes from 'prop-types';
import * as React from 'react';

class Fullstory extends React.Component {
  render() {
    return null; // Nothing to render since the JS evaluates client side
  }
  componentDidMount() {
    const { org, isProduction } = this.props;
    const { name, email, sub } = this.props;

    // If this is not production, bail out and don't run this JS
    if (!isProduction) return;

    // Disable eslint for this copy paste code from Fullstory so that we can easily
    //  upgrade / change this as necessary
    /*eslint-disable */
    window['_fs_debug'] = false;
    window['_fs_host'] = 'fullstory.com';
    window['_fs_org'] = org;
    window['_fs_namespace'] = 'FS';
    (function(m, n, e, t, l, o, g, y) {
      if (e in m) {
        if (m.console && m.console.log) {
          m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');
        }
        return;
      }
      g = m[e] = function(a, b) {
        g.q ? g.q.push([a, b]) : g._api(a, b);
      };
      g.q = [];
      o = n.createElement(t);
      o.async = 1;
      o.src = 'https://' + _fs_host + '/s/fs.js';
      y = n.getElementsByTagName(t)[0];
      y.parentNode.insertBefore(o, y);
      g.identify = function(i, v) {
        g(l, { uid: i });
        if (v) g(l, v);
      };
      g.setUserVars = function(v) {
        g(l, v);
      };
      g.identifyAccount = function(i, v) {
        o = 'account';
        v = v || {};
        v.acctId = i;
        g(o, v);
      };
      g.clearUserCookie = function(c, d, i) {
        if (!c || document.cookie.match('fs_uid=[`;`]*`[`;`]*`[`;`]*`')) {
          d = n.domain;
          while (1) {
            n.cookie = 'fs_uid=;domain=' + d + ';path=/;expires=' + new Date(0).toUTCString();
            i = d.indexOf('.');
            if (i < 0) break;
            d = d.slice(i + 1);
          }
        }
      };
    })(window, document, window['_fs_namespace'], 'script', 'user');

    // Also ignore es lint on the FS becuase it's created by the above code and doesn't exist
    //  for linting in this context
    FS.identify(sub, {
      displayName: name,
      email: email
    });
    /*eslint-enable */
  }
}

Fullstory.propTypes = {
  org: PropTypes.string,
  isProduction: PropTypes.bool,
  name: PropTypes.string,
  email: PropTypes.string,
  sub: PropTypes.string
};

export default Fullstory;
