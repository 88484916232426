/** @jsx jsx */
// eslint-disable-next-line
import React from "react";
import { CommentChat } from "react-cimpress-comment";
import { css, jsx } from "@emotion/core";
import auth from "../../../auth";
import { useChatContext } from "../store/store";
import { shapes } from "@cimpress/react-components";
import StartConversationButton from "./StartConversationButton";
import AddAttachmentButton from "./attachment/AddAttachmentButton";
import Loading from "../../../components/Loading";
import Center from "../../../components/Center";
const { Robot } = shapes;

const MainChatWindow = props => {
  const {
    selectedConversation,
    conversations,
    loadingConversations
  } = useChatContext();

  let inner;

  if (selectedConversation) {
    inner = (
      <>
        <div
          css={css`
            overflow-y: auto;
            height: 100%;
            position: relative;
            & > div:first-child {
              display: flex;
              flex-direction: column;
              height: 100%;
              padding: 0 10px;
              .comments-add {
                margin-left: 50px;
              }
              & .bubble.right {
                margin: 2px 10px 2px auto;
              }
              & > .comments {
                height: 60vh;
                overflow-y: auto;
                overflow-x: auto;
                flex: 1 0 auto;
                display: flex;
                flex-direction: column;
                & > .no-comments {
                  text-align: center;
                  margin: auto 0;
                }
              }
              * > .comment-body {
                max-width: 500px;
              }
            }
          `}
        >
          <CommentChat
            resourceUri={`https://stg-pricingterm.ff.cimpress.io/healthcheck?conversationId=${selectedConversation}`}
            accessToken={auth.getAccessToken()}
            showVisibilityLevels={false}
            textOverrides={{
              placeholder: "Type something here!",
              subscribe: "Subscribe to this conversation",
              unsubscribe: "Unsubscribe from this conversation"
            }}

          />
          <div
            css={css`
              position: absolute;
              left: 8px;
              bottom: 14px;
            `}
          >
            <AddAttachmentButton />
          </div>
          {/*<ConversationHistory />*/}
        </div>
        {/*<TypeArea />*/}
      </>
    );
  } else if (loadingConversations) {
    inner = (
      <Center>
        <Loading />
      </Center>
    );
  } else if (conversations.length === 0) {
    inner = (
      <Center>
        <Robot bsStyle="success" alternate />{" "}
        <h4>
          You don't have any conversations.
          <StartConversationButton />
        </h4>
      </Center>
    );
  } else {
    inner = (
      <Center>
        <h4>Please select a conversation.</h4>
      </Center>
    );
  }

  return (
    <div
      css={css`
        border: 1px solid #c4c4c4;
        border-left: none;
        background: #ffffff;
        width: 100%;
        display: flex;
        flex-direction: column;
      `}
    >
      {inner}
    </div>
  );
};

export default MainChatWindow;
