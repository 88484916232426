import React from "react";
import { Modal } from "@cimpress/react-components";
import { useChatContext } from "../store/store";

const StartConversationModal = ({ opened, closeModal }) => {
  const { addConversation } = useChatContext();

  return (
    <Modal
      show={opened}
      onRequestHide={closeModal}
      title="Start Conversation"
      closeButton={true}
      footer={
        <button className="btn btn-primary" onClick={addConversation}>
          Send
        </button>
      }
    ></Modal>
  );
};

export default StartConversationModal;
