/** @jsx jsx */
// eslint-disable-next-line
import React from "react";
import Conversations from "./Conversations";
import MainChatWindow from "./MainChatWindow";
import { css, jsx } from "@emotion/core";
import { useChatContext } from "../store/store";

const ChatPlatform = () => {
  return (
    <useChatContext.Provider>
      <div
        css={css`
          display: flex;
          justify-content: center;
          width: 100%;
          max-height: 80vh;
          min-height: 80vh;
        `}
      >
        <Conversations />
        <MainChatWindow />
      </div>
    </useChatContext.Provider>
  );
};

export default ChatPlatform;
