// @flow
import fetchWithAuth from './fetchWithAuth';

export const endpointUrl = "https://stg-conversations.financialflows.cimpress.io";

export const createConversation = async (subject, accountId) => {
  const body = JSON.stringify({
    subject,
    participants: [
      {
        accountId
      }
    ]
  });

  return await fetchWithAuth({ endpointUrl, method: 'POST', route: `api/v0/conversations`, body });
};

export const postMessage = async (conversationId, content) => {
  const body = JSON.stringify({
    content
  });

  return await fetchWithAuth({ endpointUrl, method: 'POST', route: `api/v0/conversations/${conversationId}/messages`, body });
};


