/** @jsx jsx */
// eslint-disable-next-line
import React from "react";
import styled from "@emotion/styled";
import { css, jsx } from "@emotion/core";
import ConversationPreviewItem from "./ConversationPreviewItem";
import StartConversationButton from "./StartConversationButton";
import { useChatContext } from "../store/store";

const ConversationsContainer = styled.div`
  position: relative;
  background: #333943;
  flex: 1 0 350px;
  max-width: 350px;
  border-right: 1px solid #c4c4c4;
  display: flex;
  flex-direction: column;
`;

const Conversations = () => {
  const {
    conversations,
    selectedConversation,
    selectConversation,
    loadingConversations
  } = useChatContext();

  if (loadingConversations || conversations.length === 0) {
    return null;
  }

  return (
    <ConversationsContainer>
      <h4
        css={css`
          padding: 15px 15px;
          color: #eff3f5;
          border-bottom: 1px solid #697582;
          margin: 0;
        `}
      >
        Conversations
      </h4>
      <div
        css={css`
          height: 100%;
          overflow-y: auto;
        `}
      >
        {conversations.map(props => (
          <ConversationPreviewItem
            id={props.id}
            {...props}
            selected={selectedConversation === props.id}
            onClick={() => selectConversation(props.id)}
          />
        ))}
      </div>
      <StartConversationButton />
    </ConversationsContainer>
  );
};

export default Conversations;
