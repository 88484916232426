export default {
  conversations: [
    {
      id: 1,
      subject: "Requesting to purchase dino t-shirts",
      participants: [
        {
          accountId: "myAccountId",
          name: "My Business"
        },
        {
          accountId: "mUushi4JkEaX1E1bp3xSm7",
          name: "Megaprint"
        }
      ],
      _links: {
        self: {
          // TODO: Should be something that's selfhosted. Using healthcheck of an existing service for now since its a public route.
          //  Should be something like https://conversations.cimpress.io/v1/conversations/1
          href:
            "https://stg-pricingterm.ff.cimpress.io/healthcheck?conversationId=1"
        },
        comments: {
          href:
            "https://comment.trdlnk.cimpress.io/v0/resources/https://stg-pricingterm.ff.cimpress.io/healthcheck?conversationId=1"
        }
      }
    },
    {
      id: 2,
      subject: "Requesting to purchase Rabbit shirt (CIM-234)",
      participants: [
        {
          accountId: "myAccountId",
          name: "My Business"
        },
        {
          accountId: "mUushi4JkEaX1E1bp3xSm7",
          name: "Megaprint"
        }
      ],
      _links: {
        self: {
          href:
            "https://stg-pricingterm.ff.cimpress.io/healthcheck?conversationId=2"
        },
        comments: {
          href:
            "https://comment.trdlnk.cimpress.io/v0/resources/https://stg-pricingterm.ff.cimpress.io/healthcheck?conversationId=2"
        }
      }
    },
    {
      id: 3,
      subject: "Price is missing for these products",
      participants: [
        {
          accountId: "myAccountId",
          name: "My Business"
        },
        {
          accountId: "mUushi4JkEaX1E1bp3xSm7",
          name: "Cimpress"
        }
      ],
      _links: {
        self: {
          href:
            "https://stg-pricingterm.ff.cimpress.io/healthcheck?conversationId=3"
        },
        comments: {
          href:
            "https://comment.trdlnk.cimpress.io/v0/resources/https://stg-pricingterm.ff.cimpress.io/healthcheck?conversationId=3"
        }
      }
    },
    {
      id: 4,
      subject: "Deals on red cool pants",
      participants: [
        {
          accountId: "myAccountId",
          name: "My Business"
        },
        {
          accountId: "mUushi4JkEaX1E1bp3xSm7",
          name: "Cool Pants"
        }
      ],
      _links: {
        self: {
          href:
            "https://stg-pricingterm.ff.cimpress.io/healthcheck?conversationId=4"
        },
        comments: {
          href:
            "https://comment.trdlnk.cimpress.io/v0/resources/https://stg-pricingterm.ff.cimpress.io/healthcheck?conversationId=4"
        }
      }
    }
  ]
};
