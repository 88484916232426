import { useEffect, useReducer } from "react";
import createUseContext from "constate";
import mockData from "../chat/mockData";
import CommentsClient from "react-cimpress-comment/lib/clients/CommentsClient";
import auth from "../../../auth";

const initialState = {
  selectedConversation: null,
  conversations: [],
  loadingConversations: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case "GET_THREADS":
      return { ...state, conversations: action.payload };
    case "SELECT_THREAD":
      return { ...state, selectedConversation: action.payload };
    case "ADD_THREAD":
      return {
        ...state,
        conversations: [...state.conversations, action.payload]
      };
    case "SET_LOADING_THREADS":
      return { ...state, loadingConversations: action.payload };
    default:
      throw new Error();
  }
};

const useChat = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { selectedConversation, conversations, loadingConversations } = state;

  const getConversations = () => {
    dispatch({
      type: "SET_LOADING_THREADS",
      payload: true
    });
    const fetchPromises = mockData.conversations.map(conv => {
      const client = new CommentsClient(
        auth.getAccessToken(),
        conv._links.self.href
      );
      return client.fetchComments().then(res => {
        const recentMsg = res.responseJson.length
          ? res.responseJson[res.responseJson.length - 1]
          : {};
        return {
          ...conv,
          recentMsgDate: recentMsg.updatedAt,
          recentMsg: recentMsg.comment
        };
      });
    });
    return Promise.all(fetchPromises).then(res => {
      dispatch({
        type: "GET_THREADS",
        payload: res
      });
      dispatch({
        type: "SET_LOADING_THREADS",
        payload: false
      });

      return res;
    });
  };

  useEffect(() => {
    getConversations();
  }, []);

  const selectConversation = conversationId =>
    dispatch({
      type: "SELECT_THREAD",
      payload: conversationId
    });

  // TODO: Make this actually add.
  const addConversation = conversation =>
    dispatch({
      type: "ADD_THREAD",
      payload: conversation
    });
  return {
    conversations,
    selectedConversation,
    selectConversation,
    addConversation,
    getConversations,
    loadingConversations
  };
};

export const useChatContext = createUseContext(useChat);
