import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';
import auth from './auth';
import Loading from './components/Loading';
import OtherPage from './components/OtherPage';
import PageNotFound from './components/404';
import Shell from './components/Shell';
import Chat from './pages/marketplace-inquiry/chat';
import FullStory from './components/FullStory';
import InquiryButton from './pages/modal/InquiryButton';

const trackingId = 'UA-156573591-1';
ReactGA.initialize(trackingId);
auth.on('tokenExpired', () => auth.login({ nextUri: window.location.pathname, forceLogin: true }));

auth.on('authenticated', () =>
  ReactGA.set({
    userId: auth.getProfile().sub,
    // userId
    dimension1: auth.getProfile()['https://claims.cimpress.io/canonical_id'],
    // user's account
    dimension2: auth.getProfile()['https://claims.cimpress.io/account'],
    // cimpress internal flag
    dimension3: auth.getProfile()['https://claims.cimpress.io/cimpress_internal']
  })
);

class App extends Component {
  state = {
    authenticating: false,
    authenticationError: undefined
  };

  constructor(props) {
    super(props);

    const basename = process.env.REACT_APP_ROUTER_BASENAME || '';
    this.history = createBrowserHistory({ basename });
    this.history.listen(({ pathname, search }) => {
      const page = `${pathname}${search}`;
      ReactGA.set({ page });
      ReactGA.pageview(page);
    });
  }

  componentDidMount() {
    if (!auth.isLoggedIn()) {
      this.setState({ authenticating: true });
      auth
        .ensureAuthentication({
          nextUri: window.location.pathname + window.location.search
        })
        .then(() => {
          this.setState({ authenticating: false });
        })
        .catch(err => {
          this.setState({ authenticationError: err });
        });
    }
  }

  render() {
    const isLoggedIn = auth.isLoggedIn();

    const { sub, name, email } = isLoggedIn
      ? auth.getProfile()
      : { sub: null, name: 'Guest', email: null };

    const isProduction = process.env.REACT_APP_ENVIRONMENT_NAME === 'Production';

    const { authenticating, authenticationError } = this.state;
    return (
      <div>
        {authenticating ? (
          <Loading />
        ) : auth.isLoggedIn() ? (
          <Router history={this.history}>
            <Shell>
              <Switch>
                <Route exact path="/(index.html)?" component={Chat} />
                <Route path="/button" component={InquiryButton} />
                <Route path="/other" component={OtherPage} />
                <Route component={PageNotFound} />
              </Switch>
            </Shell>
          </Router>
        ) : authenticationError ? (
          <div>Unexpected error encountered. {authenticationError.message}</div>
        ) : null}
        <FullStory org="97CT5" name={name} email={email} sub={sub} isProduction={isProduction} />
      </div>
    );
  }
}

export default App;
