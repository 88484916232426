import { centralizedAuth } from "@cimpress/simple-auth-wrapper";

export const CLIENT_ID = "rkuPpg6u2l8T6msdcZljWI35mXuPOcdy";

const auth = new centralizedAuth({
  clientID: CLIENT_ID,
  redirectRoute: "/"
});

export default auth;
