/** @jsx jsx */
// eslint-disable-next-line
import React, { useState } from "react";
import { Button } from "@cimpress/react-components";
import { css, jsx } from "@emotion/core";
import StartConversationModal from "./StartConversationModal";

const StartConversationButton = () => {
  const [modalOpened, setModalOpened] = useState(false);

  return (
    <>
      {/* TODO: Re-enable */}
      <Button
        disabled
        type="link"
        style={{ width: "100%" }}
        css={css`
          align-self: center;

          :hover {
            background-color: #3a414c;
          }
          :active {
            background-color: #697582;
          }
        `}
        onClick={() => setModalOpened(true)}
      >
        <h5>Start a New Conversation</h5>
      </Button>
      <StartConversationModal
        opened={modalOpened}
        closeModal={() => setModalOpened(false)}
      />
    </>
  );
};

export default StartConversationButton;
