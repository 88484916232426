/** @jsx jsx */
// eslint-disable-next-line
import React from "react";
import { css, jsx } from "@emotion/core";
import TimeAgo from "react-timeago";

const ConversationPreviewItem = ({
  participants,
  subject,
  recentMsgDate,
  recentMsg,
  selected,
  onClick
}) => {
  const participantsButMe = participants.filter(
    p => p.accountId !== "myAccountId"
  );
  const participantNames = participantsButMe.map(p => p.name).join(", ");

  return (
    <div
      css={css`
        cursor: pointer;
        background-color: ${selected ? "#697582" : "#333943"};
        :hover {
          ${!selected ? "background-color: #3A414C;" : ""}
        }
        border-bottom: 1px solid #c4c4c4;
        padding: 8px 16px;
        min-height: 0;
      `}
      onClick={onClick}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          color: #eff3f5;
        `}
      >
        <h6
          css={css`
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
          `}
        >
          {participantNames}
        </h6>
        <p
          css={css`
            margin-right: 16px;
            font-weight: 500;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
          `}
        >
          {subject}
        </p>
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: space-between;
          `}
        >
          <p
            css={css`
              color: #8090a2;
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
            `}
          >
            {recentMsg || <i>No messages</i>}
          </p>
          <small
            css={css`
              margin-left: 16px;
            `}
          >
            <TimeAgo date={recentMsgDate} />
          </small>
        </div>
      </div>
    </div>
  );
};

export default ConversationPreviewItem;
