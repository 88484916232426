import React from 'react';
import { Button } from '@cimpress/react-components';
import InquiryModal from './InquiryModal';
import { useState } from 'react';

const skuInfo = {
  name: "Cooler Bag",
  sku: "CIM-QGX95DK3",
  description: "Bullet™ Larvik cooler bag",
  sceneUrl: "https://rendering.documents.cimpress.io/preview?width=250&height=250&scene=https://scene.products.cimpress.io/v1/scenes/547d262b-8c33-45b3-bc78-ebeb8ffff30a"
}

const account = {
  accountName: 'Vistaprint',
  accountId: 'ozoDdrmewShEcbUDWX8J3V'
}

export function InquiryButton() {
  const [show, setShow] = useState(false);

  return (
    <div>
      <Button
        onClick={() => setShow(true)}
        style={{ marginLeft: "10px" }}
        className={`btn btn-primary`}>
        {"Contact Seller"}
      </Button>
      <InquiryModal
        account={account}
        onCloseModal={() => setShow(false)}
        skuInfo={skuInfo}
        show={show}>
      </InquiryModal>
    </div>
  );
}

export default InquiryButton;