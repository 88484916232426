import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '@cimpress-technology/react-platform-header';
import CssLoader from '@cimpress/react-components/lib/CssLoader';
import auth from '../auth';

export default class App extends Component {
  static propTypes = {
    children: PropTypes.object.isRequired
  };

  render() {
    let homeLink = <Link to="/">Messaging Platform</Link>;
    let otherLink = <Link to="/button">Inquiry Button</Link>;

    return (
      <CssLoader>
        <Header
          accessToken={auth.getAccessToken()}
          appTitle={<Link to="/">Conversations</Link>}
          appLinks={[homeLink, otherLink]}
          profile={auth.getProfile()}
          onLogInClicked={() => auth.login()}
          onLogOutClicked={() => auth.logout('/')}
          isLoggedIn={auth.isLoggedIn()}
        />

        <div style={{ minHeight: 'calc(100vh - 225px)' }}>{this.props.children}</div>
      </CssLoader>
    );
  }
}
